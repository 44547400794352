import React from 'react';
import Link from '@ui/components/Link/Link';
import Icon from '@ui/components/icon/icon';
import { cn } from '@ngg/storefront-utils';
import { Dictionary } from '@ts/dictionary';
import { Heading } from '@ui/components/Heading/Heading';
import Paragraph from '@ui/components/Paragraph/Paragraph';

const Error = ({
  className,
  linkClassName,
  dictionary,
}: {
  className?: string;
  linkClassName?: string;
  dictionary: Dictionary;
}) => {
  return (
    <div className={cn(['p-4', className])}>
      <Link
        className={cn([
          'inline-flex items-center lg:hover:underline',
          linkClassName,
        ])}
        href="/">
        <Icon name="chevron" className="-rotate-90" />
        {dictionary?.backToHomePage}
      </Link>
      <section className="max-w-3xl lg:mb-[6.5rem]">
        <header>
          <Heading
            as="h1"
            className="mb-10 mt-8 text-4xl font-semibold leading-tight lg:mb-[4.5rem] lg:text-[3.5rem]">
            {dictionary?.somethingWentWrong}
          </Heading>
        </header>
        <blockquote className="mb-4 text-2xl font-semibold leading-[2rem] lg:mb-6 lg:text-4xl lg:leading-[2.75rem]">
          {dictionary?.pageNotFoundQuoteText}
        </blockquote>
        <Paragraph className="mb-6">
          {dictionary?.pageNotFoundQuoteAuthor}
        </Paragraph>
      </section>
    </div>
  );
};

export default Error;
