'use client';

import { cn } from '@ngg/storefront-utils';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type HTMLAttributes } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

type HeadingProps = VariantProps<typeof headingVariants> &
  HTMLAttributes<HTMLHeadingElement> & {
    asChild?: boolean;
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  };

const headingVariants = tv(
  {
    variants: {
      size: {
        1: 'text-4xl',
        2: 'text-3xl',
        3: 'text-2xl',
        4: 'text-xl',
        5: 'text-lg',
      },
      as: {
        h1: 'font-semibold',
        h2: 'font-semibold',
        h3: 'font-normal',
        h4: 'font-normal',
        h5: 'font-normal',
        h6: 'font-normal',
      },
    },
  },
  {
    responsiveVariants: true,
  },
);

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ asChild, as = 'h2', size, className, ...props }, ref) => {
    const Comp = asChild ? Slot : as;

    return (
      <Comp
        ref={ref}
        className={cn([headingVariants({ size }), className])}
        {...props}
      />
    );
  },
);

Heading.displayName = 'Heading';

export { Heading, headingVariants };
